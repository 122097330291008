.ferienhausverwaltung-v1 {
	.quote {
		max-width: 350px;
		margin: 50px auto 0;

		i {
			font-size: 30px;
			color: #1d71b8;
		}

		h4 {
			margin: 15px 0 15px 0;
			font-size: 20px;
		}

		p {
			color: #1d71b8;
			font-weight: 500;
		}
	}
}